import dayjs from 'dayjs';

export const pluralize = (number, one, two, five) => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
};

export const truncateString = (str, limit) => {
  if (str.length > limit) {
    return `${str.substr(0, limit - 1).trimRight()}…`;
  }
  return str;
};

export const formatDate = (date = Date.now(), pattern = 'DD.MM.YYYY') => dayjs(date).format(pattern);

export const dateToStr = (date, format = 'YYYY-MM-DD') => {
  if (date) {
    if (date instanceof Date) {
      return dayjs(date).format(format); // Форматируем, только если это дата
    }
    return date;
  }
  return '';
};

export const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export const base64ToRaw = (base64) => base64.substring(base64.indexOf('base64,') + 7);

export const fileToBase64 = async (input) => {
  const { files } = input;
  const [file] = files;
  if (file) {
    try {
      return await toBase64(file);
    } catch (e) {
      console.warn(e);
    }
  }
  return '';
};

export const cutToComma = (string) => {
  const cut = string.indexOf(',');
  return string.slice(cut + 1);
};
