<template>
  <VContainer
    class="pa-0"
  >
    <VRow>
      <VCol
        cols="12"
        class="pb-1"
      >
        <h2 class="tt-text-title-2">
          {{ edit? 'Работа с обращением' : 'Решенное обращение' }}
        </h2>
      </VCol>
      <VCol>
        <div
          class="d-flex justify-space-between cursor--pointer mb-3"
          data-test="appeal-form-goto-staff-id"
          @click.stop="$router.push(`/staff/${editEntity.staff.id}`)"
        >
          <div class="d-flex align-start">
            <NewSAvatar
              data-test="appeal-form-staff-avatar"
              :img="editEntity.staff.photo_url"
              :name="`${editEntity.staff.last_name} ${editEntity.staff.first_name}`"
            />
            <div
              class="ml-4 appeal-form__staff-name"
            >
              <h4
                class="tt-text-body-2"
                data-test="appeal-form-staff-name"
              >
                {{ `${editEntity.staff.last_name} ${editEntity.staff.first_name}` }}
              </h4>
              <template v-if="needAppealTracks">
                <span
                  data-test="name-position"
                  class="tt-light-mono-46--text tt-text-body-2"
                  :data-test-label="editEntity.staff.id"
                >{{ editEntity.staff.position }}</span>
              </template>
              <template v-else>
                <label
                  v-if="location"
                  class="tt-text-body-2 tt-light-mono-46--text cursor--pointer"
                  data-test="appeal-form-staff-location"
                >
                  {{ location.name }}<span v-if="editEntity.staff.position">, {{ editEntity.staff.position }}</span>
                </label>
              </template>
            </div>
          </div>
          <span
            data-test="appeal-form-created-appeal"
            class="text-right tt-text-body-2 tt-light-mono-46--text"
          >
            {{ formatDate(editEntity.created, 'DD.MM.YYYY HH.mm') }}
          </span>
        </div>
        <p
          class="mb-1 tt-text-body-2"
          data-test="appeal-form-problem"
        >
          {{ editEntity.problem }}
        </p>
        <template v-if="needAppealTracks">
          <div
            data-test="name-position"
            class="tt-light-mono-46--text tt-text-body-2"
            :data-test-label="editEntity.staff.id"
          >
            <span
              v-if="location"
              data-test="track-name"
            >{{ location.name }} • </span>
            <span
              v-if="level"
              data-test="level-name"
            >{{ level.name }} • </span>
            <span
              data-test="appeal-form-task-name"
              @click="$router.push(`/content/${editEntity.task.id}`)"
            >
              {{ editEntity.task.name }}
            </span>
          </div>
        </template>
        <template v-else>
          <p
            class="tt-text-body-2 tt-light-mono-46--text cursor--pointer mb-0"
            data-test="appeal-form-task-name"
            @click="$router.push(`/content/${editEntity.task.id}`)"
          >
            {{ editEntity.task.name }}
          </p>
        </template>
        <VDivider class="mt-4 mb-4" />

        <template
          v-if="edit"
          data-test="appeal-form-unresolved"
        >
          <TTTextarea
            :maxlength="10000"
            :value="entity.resolve"
            label="Комментарий"
            data-test="appeal-form-text-field"
            class="mb-2"
            auto-grow
            hide-details="auto"
            single-line
            :rows="3"
            placeholder="Введите комментарий"
            :error="validation('resolve').status"
            :error-messages="validation('resolve').value"
            @input="v => handleChange('resolve',v)"
          />
          <div
            class="mb-4 tt-text-body-2 tt-light-mono-46--text"
            data-test="appeal-form-explanatory-text"
          >
            Если обращение требует ответа сотруднику, оставьте комментарий.
          </div>
          <div class="d-flex align-center justify-end">
            <TTBtn
              color="tt-ghost--text"
              class="mr-4"
              depressed
              data-test="cancel-button"
              @click="handleClose"
            >
              Отменить
            </TTBtn>
            <TTBtn
              depressed
              data-test="save-button"
              :disabled="saving"
              :loading="saving"
              @click="save"
            >
              Перенести в решенные
            </TTBtn>
          </div>
        </template>
        <template
          v-else
          data-test="appeal-form-resolved"
        >
          <div class="d-flex align-start mb-2">
            <div>
              <h4
                class="tt-text-body-2"
                data-test="appeal-form-staff-name"
              >
                {{ `${editEntity.resolved_user.last_name} ${editEntity.resolved_user.first_name}` }}
              </h4>
            </div>
            <VSpacer />
            <span
              class="tt-text-body-2 tt-light-mono-46--text"
              data-test="appeal-form-resolved-date"
            >
              {{ formatDate(editEntity.resolved, 'DD.MM.YYYY HH.mm') }}
            </span>
          </div>
          <p
            class="tt-text-body-2 mb-0"
            data-test="appeal-form-resolve"
          >
            {{ editEntity.resolve }}
          </p>
        </template>
      </VCol>
    </VRow>
    <TTBtn
      width="20"
      height="20"
      min-width="20"
      absolute
      top
      right
      depressed
      color="transparent"
      data-test="appeal-button-close-form"
      @click="handleClose"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-times
      </VIcon>
    </TTBtn>
  </VContainer>
</template>

<script>
import { validation, handleError, watchValidationStatus } from '@/services';
import { formatDate } from '@/utils';
import * as snamiApi from '@/services/backend/snami';
import NewSAvatar from '@/components/ui/NewSAvatar.vue';

export default {
  name: 'AppealForm',
  components: { NewSAvatar },
  props: {
    editEntity: {
      type: Object,
      default: () => {
      },
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      validationStatus: [],
      entity: {
        resolve: '',
      },
      location: null,
      level: null,
    };
  },
  computed: {
    needAppealTracks() {
      return this.$di.ff.get('appeal_tracks');
    },
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  mounted() {
    if (this.edit) {
      this.handleEntity();
    }
    this.load();
  },
  methods: {
    validation,
    handleError,
    save() {
      this.saving = true;
      const data = {
        id: this.editEntity.id,
        resolve: this.entity.resolve,
      };
      this.$repositories.appeal.resolve({ data }).then(() => {
        this.$emit('save');
      }).catch((e) => {
        console.warn(e);
        this.handleError(e);
      }).finally(() => {
        this.saving = false;
      });
    },
    handleEntity() {
      this.entity = this.editEntity;
    },
    handleClose() {
      this.entity.resolve = '';
      this.$emit('close');
    },
    formatDate,
    handleChange(name, value, target) {
      this.entity[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    load() {
      if (this.editEntity?.task?.location_id) {
        this.$repositories.location.list({ filter: { id: this.editEntity.staff.location_id } })
          .then((r) => {
            const { data } = r.data;
            if (data) {
              [this.location] = data;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
      if (this.editEntity?.task?.level_id) {
        snamiApi.getLevel({ filter: { id: this.editEntity.task.level_id } })
          .then((res) => {
            const { data } = res.data;
            if (data) {
              [this.level] = data;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
  },
};
</script>

<style>
  /* .appeal-form__staff-name {
    flex: 0 0 57%;
  } */
</style>
