<template>
  <VContainer
    fluid
  >
    <DialogWrapper
      v-model="appealForm"
      width="676"
      data-test="appeal-form"
      @click:outside="closeModal('appealForm')"
    >
      <AppealForm
        v-if="appealForm"
        :edit="edit"
        :edit-entity="activeEntity"
        @close="closeModal('appealForm')"
        @save="handleSave('appealForm')"
      />
    </DialogWrapper>
    <DialogWrapper
      v-model="deleteDialog"
      width="396"
      hide-overlay
    >
      <DeleteForm
        v-if="deleteDialog"
        data-test="appeal-delete"
        @delete="handleDelete"
        @cancel="deleteDialog = false"
      >
        <h3 class="delete-form__title">
          Удалить обращение?
        </h3>
        <p data-test="appeal-delete-form-text">
          Обращение пользователя
          {{ toDelete.staff.last_name }} {{ toDelete.staff.first_name }} от
          {{ $dayjs(toDelete.created).format('DD.MM.YYYY') }} и решение по нему будет удалено.
        </p>
      </DeleteForm>
    </DialogWrapper>

    <VRow>
      <VCol>
        <h1 class="tt-text-headline-1">
          Обращения
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol :cols="needAppealTracks ? 4 : 6">
        <SelectTags
          v-if="needAppealTracks"
          v-model="selectedTracks"
          label="Трек"
          :items="tracks"
          data-test-label="select-tracks"
        />
        <TTSelect
          v-else
          ref="locationDropDown"
          placeholder="Выбор локации"
          :value="locationId"
          :items="locations"
          item-text="name"
          item-value="id"
          large
          label="Локация"
          :menu-props="{ auto : true }"
          class="tt-select-attach"
          attach
          data-test="appeal-choose-location"
          @change="v => handleLocation(v)"
        >
          <template #selection="{item,disabled}">
            <TextTemplate
              class="v-select__selection v-select__selection--comma"
              :disabled="disabled"
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
          <template #item="{item}">
            <TextTemplate
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
        </TTSelect>
      </VCol>
    </VRow>
    <VRow>
      <VCol class="pt-0">
        <VTabs
          v-model="isResolved"
          slider-color="white"
          color="#000"
          active-class="active-tab-background"
          data-test="appeal-tabs"
        >
          <VTabsSlider color="#000" />
          <VTab
            data-test="appeal-tab-unresolved"
            value="false"
            href="#false"
          >
            Нерешенные
          </VTab>
          <VTab
            data-test="appeal-tab-resolved"
            value="true"
            href="#true"
          >
            Решенные
          </VTab>
        </VTabs>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTDataTable
          :items="appeal"
          :headers="appealHeaders"
          :items-per-page="-1"
          extra-tall
          data-test="appeal-table"
          hide-default-footer
        >
          <template #item.name="{item}">
            <div
              v-if="item.staff.location_id"
              class="d-flex align-start cursor--pointer"
              @click.stop="$router.push(`/staff/${item.staff.id}`)"
            >
              <NewSAvatar
                data-test="item-staff-avatar"
                :img="item.staff.photo_url"
                :name="`${item.staff.last_name} ${item.staff.first_name}`"
              />
              <div class="ml-4">
                <div data-test="item-staff-name">
                  {{ `${item.staff.last_name} ${item.staff.first_name}` }}
                </div>
                <div
                  class="tt-black--text text--lighten-2 cursor--pointer"
                >
                  <template v-if="needAppealTracks">
                    <span
                      data-test="name-position"
                      :data-test-label="item.staff.id"
                    >{{ item.staff.position }}</span>
                  </template>
                  <template v-else>
                    <TTTooltip
                      bottom
                      max-width="300"
                    >
                      <template #activator="{ on, attrs }">
                        <p
                          v-bind="attrs"
                          class="tt-black--text text--lighten-2 ellipsis-two-lines ellipsis mb-0"
                          v-on="on"
                        >
                          {{ getLocationName(item) }}<span v-if="item.staff.position">, {{ item.staff.position }}</span>
                        </p>
                      </template>
                      <p>
                        {{ getLocationName(item) }}<span v-if="item.staff.position">, {{ item.staff.position }}</span>
                      </p>
                    </TTTooltip>
                  </template>
                </div>
              </div>
            </div>
            <div
              v-else
              class="d-flex align-center"
            >
              <NewSAvatar
                :img="item.staff.photo_url"
                :name="`${item.staff.last_name} ${item.staff.first_name}`"
              />
              <div
                class="ml-4"
                data-test="item-staff-name"
              >
                <div>{{ `${item.staff.last_name} ${item.staff.first_name}` }}</div>
                <label class="tt-black--text text--lighten-2">
                  Уволен
                </label>
              </div>
            </div>
          </template>
          <template #item.problem="{item}">
            <div class="d-flex align-start fill-height">
              <div>
                <div
                  data-test="item-problem"
                  class="text--one-line"
                >
                  {{ item.problem }}
                </div>
                <div
                  class="text--lighten-2"
                  data-test="item-created"
                >
                  {{ $dayjs(item.created).format('DD.MM.YYYY HH.mm') }}
                </div>
              </div>
              <VSpacer />
              <div
                v-if="isResolved === 'false'"
                class="ml-12"
              >
                <VMenu
                  content-class="v-menu-shadow"
                  offset-y
                  left
                  data-test="appeal-unresolved-menu"
                >
                  <template #activator="{ on }">
                    <TTBtn
                      fab
                      small
                      depressed
                      color="transparent tt-ghost--text"
                      :ripple="false"
                      class="table-menu-button"
                      data-test="button-send-invite"
                      v-on="on"
                    >
                      <VIcon size="19">
                        fal fa-ellipsis-h
                      </VIcon>
                    </TTBtn>
                  </template>
                  <VCard class="v-menu-card">
                    <VList dense>
                      <VListItem
                        class="custom-menu-item"
                        data-test="appeal-button-reply-to-appeal"
                        @click="openModal('appealForm', item)"
                      >
                        <VListItemIcon>
                          <VIcon
                            size="19"
                            color="tt-black"
                          >
                            fal fa-comment
                          </VIcon>
                        </VListItemIcon>
                        <VListItemContent>Ответить на обращение</VListItemContent>
                      </VListItem>
                      <VListItem
                        class="custom-menu-item"
                        :to="`/content/${item.task.id}`"
                        data-test="appeal-button-goto-task"
                      >
                        <VListItemIcon>
                          <VIcon
                            size="19"
                            color="tt-black"
                          >
                            fal fa-arrow-from-left
                          </VIcon>
                        </VListItemIcon>
                        <VListItemContent>Перейти в задачу</VListItemContent>
                      </VListItem>
                    </VList>
                  </VCard>
                </VMenu>
              </div>
            </div>
          </template>
          <template #item.resolve="{item}">
            <div class="d-flex align-start fill-height">
              <div>
                <div
                  data-test="item-resolve"
                  class="text--one-line"
                >
                  {{ item.resolve }}
                </div>

                <div
                  v-if="item.resolved_user"
                  class="tt-black--text text--lighten-2"
                  data-test="item-resolved-user-name"
                >
                  {{ `${item.resolved_user.last_name} ${item.resolved_user.first_name}` }}
                </div>
                <div
                  class="tt-black--text text--lighten-2"
                  data-test="item-resoled"
                >
                  {{ $dayjs(item.resolved).format('DD.MM.YYYY HH.mm') }}
                </div>
              </div>
              <VSpacer />
              <div class="ml-4">
                <VMenu
                  content-class="v-menu-shadow"
                  offset-y
                  left
                  data-test="appeal-resolve-menu"
                >
                  <template #activator="{ on }">
                    <TTBtn
                      fab
                      small
                      depressed
                      color="transparent tt-ghost--text"
                      :ripple="false"
                      class="table-menu-button"
                      data-test="button-send-invite"
                      v-on="on"
                    >
                      <VIcon size="19">
                        fal fa-ellipsis-h
                      </VIcon>
                    </TTBtn>
                  </template>
                  <VCard class="v-menu-card">
                    <VList dense>
                      <VListItem
                        class="custom-menu-item"
                        data-test="appeal-button-show-appeal"
                        @click="openModal('appealForm', item)"
                      >
                        <VListItemIcon>
                          <VIcon
                            size="19"
                            color="tt-black"
                          >
                            fal fa-comment
                          </VIcon>
                        </VListItemIcon>
                        <VListItemContent>Посмотреть обращение</VListItemContent>
                      </VListItem>
                      <VListItem
                        class="custom-menu-item"
                        :to="`/content/${item.task.id}`"
                        data-test="appeal-button-goto-task"
                      >
                        <VListItemIcon>
                          <VIcon
                            size="19"
                            color="tt-black"
                          >
                            fal fa-arrow-from-left
                          </VIcon>
                        </VListItemIcon>
                        <VListItemContent>Перейти в задачу</VListItemContent>
                      </VListItem>
                      <VListItem
                        data-test="appeal-button-delete-appeal"
                        class="custom-menu-item"
                        @click="openDelete(item)"
                      >
                        <VListItemIcon>
                          <VIcon
                            size="19"
                            color="error"
                          >
                            fal fa-trash-alt
                          </VIcon>
                        </VListItemIcon>
                        <VListItemContent class="tt-light-red--text">
                          Удалить
                        </VListItemContent>
                      </VListItem>
                    </VList>
                  </VCard>
                </VMenu>
              </div>
            </div>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
    <VRow v-if="isLoaded && !fullLoad">
      <VCol>
        <div v-intersect.quiet="getMore" />
      </VCol>
    </VRow>
    <VRow v-if="!isLoaded && !fullLoad">
      <VCol align="center">
        <VProgressCircular
          indeterminate
          color="primary"
          data-test="appeal-preloader"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import NewSAvatar from '@/components/ui/NewSAvatar.vue';
import AppealForm from '@/components/forms/AppealForm.vue';
import DeleteForm from '@/components/forms/DeleteForm.vue';
import { pageLocationToken } from '@/services';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import TextTemplate from '@/components/shared/TextTemplate.vue';
import SelectTags from '@/components/shared/SelectTags.vue';
import { mapActions } from 'vuex';

export default {
  name: 'Appeal',
  components: {
    TextTemplate,
    DialogWrapper,
    AppealForm,
    DeleteForm,
    NewSAvatar,
    SelectTags,
  },
  data() {
    return {
      locationId: null,
      default_location: {
        id: null,
        name: 'Все локации',
      },
      locations: [],
      locationsMap: {},
      isResolved: 'false',
      appeal: [],
      appealForm: false,
      deleteDialog: false,
      edit: false,
      activeEntity: {},
      toDelete: {},
      page: {},
      isLoaded: false,
      tracks: [
        {
          text: 'Очень большое название чтобы проверить',
          value: 0,
        },
        {
          text: 'Еще одно очень большое название',
          value: 2,
        },
      ],
      selectedTracks: [0, 2],
    };
  },
  computed: {
    currentPage() {
      return this.page.n || 0;
    },
    totalPages() {
      return this.page.total_page || 0;
    },
    fullLoad() {
      return this.currentPage >= this.totalPages;
    },
    appealHeaders() {
      const headers = [
        {
          text: 'Сотрудники',
          value: 'name',
          sortable: false,
          width: 280,
        },
        {
          text: 'Обращение',
          sortable: false,
          value: 'problem',
        },
        {
          text: 'Решение',
          sortable: false,
          value: 'resolve',
          width: 300,
        },
      ];
      if (this.isResolved === 'false') {
        return headers.filter((header) => header.value !== 'resolve');
      }
      return headers;
    },
    needAppealTracks() {
      return this.$di.ff.get('appeal_tracks');
    },
  },
  watch: {
    isResolved() {
      this.getData();
    },
  },
  created() {
    const loc = pageLocationToken.get();
    this.locationId = loc ? parseInt(loc, 10) : null;
    this.$repositories.location.list({ filter: {} }).then((r) => {
      const { data } = r.data;
      this.locations = data;
      data.forEach((location) => {
        this.locationsMap[location.id] = location;
      });
      this.locations.unshift(this.default_location);
    }).catch((e) => console.warn(e));
    this.getData(this.isResolved);
  },
  methods: {
    ...mapActions('customerInfo', ['getCustomerInfo']),
    handleLocation(id) {
      pageLocationToken.set(id);
      this.locationId = id;
      this.getData(this.isResolved);
    },
    handleSave(name) {
      const { id } = this.activeEntity;
      this.closeModal(name);
      if (id) {
        const appealIndex = this.appeal.findIndex((appeal) => appeal.id === id);
        if (appealIndex !== -1) {
          this.appeal.splice(appealIndex, 1);
        } else {
          this.getData(this.isResolved);
        }
      } else {
        this.getData(this.isResolved);
      }
      this.getCustomerInfo();
    },
    openDelete(item) {
      this.deleteDialog = true;
      this.toDelete = item;
    },
    handleDelete() {
      this.$repositories.appeal.delete({ data: { id: this.toDelete.id } }).then(() => {
        const appealIndex = this.appeal.findIndex((appeal) => appeal.id === this.toDelete.id);
        if (appealIndex !== -1) {
          this.appeal.splice(appealIndex, 1);
        } else {
          this.getData(this.isResolved);
        }
        this.deleteDialog = false;
        this.toDelete = {};
      }).catch((e) => console.warn(e));
    },
    openModal(name, item) {
      this[name] = true;
      if (item) {
        this.activeEntity = item;
        this.edit = this.isResolved === 'false';
      }
    },
    closeModal(name) {
      this.edit = false;
      this[name] = false;
      this.toDelete = {};
      this.activeEntity = {};
    },
    // здесь начинается часть, благодаря которой происходит автоподгруза при скролле
    handleData(data) {
      this.appeal = [...this.appeal, ...data];
    },
    // запрашивает data
    getLocationName(item) {
      if (item.staff.location_id && this.locationsMap[item.staff.location_id]) {
        return this.locationsMap[item.staff.location_id].name;
      }
      return '';
    },
    getData() {
      this.appeal = [];
      this.page = {};
      this.isLoaded = false;
      // this.isResolved = arg;
      this.$repositories.appeal
        .list({ data: { filter: { is_resolved: this.isResolved, location_id: this.locationId } } })
        .then((r) => {
          const { data, page } = r.data;
          this.handleData(data);
          this.page = page;
        }).catch((e) => { console.warn(e); }).finally(() => { this.isLoaded = true; });
    },
    // подгружает страницы
    getMore(entries, observer, isIntersecting) {
      if (isIntersecting && this.isLoaded) {
        this.isLoaded = false;
        this.$repositories.appeal.list({
          data: {
            page: this.currentPage + 1,
            filter: { is_resolved: this.isResolved, location_id: this.locationId },
          },
        }).then((r) => {
          const { data, page } = r.data;
          this.handleData(data);
          this.page = page;
        }).catch((e) => { console.warn(e); }).finally(() => {
          this.$nextTick(() => { this.isLoaded = true; });
        });
      }
    },
  },
};
</script>

<style scoped>
/* stylelint-ignore no-empty-source */
</style>
