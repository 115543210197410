<template>
  <TTSelect
    v-model="selected"
    class="select-tags"
    v-bind="$props"
    large
    multiple
    :data-test-label="dataTestLabel"
    v-on="$listeners"
  >
    <template #prepend-inner>
      <TTChip
        v-if="selected.length"
        class="close-chip"
        close
        close-icon="fal fa-times"
        @click:close="onClearHandler"
      >
        {{ selected.length }}
      </TTChip>
    </template>
    <template #item="{item, attrs, on}">
      <VListItem
        #default="{ active }"
        v-bind="attrs"
        data-test="select-tags-item"
        :data-test-label="item.value"
        v-on="on"
      >
        <VListItemAction>
          <VCheckbox
            class="inner-checkbox pa-0 ma-0"
            :ripple="false"
            :input-value="active"
            data-test="checkbox"
          />
        </VListItemAction>
        <VListItemContent>
          <VListItemTitle>
            <VRow
              no-gutters
              align="center"
            >
              <TTChip
                :outlined="false"
                color="tt-light-blue pale"
                text-color="tt-blue"
                class="cursor--pointer"
                data-test-label="chip"
              >
                {{ item.text }}
              </TTChip>
            </VRow>
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
    </template>
  </TTSelect>
</template>
<script>
export default {
  name: 'SelectTags',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: Array,
      required: true,
    },
    dataTestLabel: {
      type: String,
      default: 'select-tags',
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selected = value;
      },
    },
    selected(value) {
      this.$emit('input', value);
    },
  },
  methods: {
    onClearHandler() {
      this.selected = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.inner-checkbox::v-deep {
  .v-input__slot {
    background:inherit;
    border: 0;
    height:20px;
  }
}

.select-tags::v-deep {
  .v-text-field .v-input__prepend-inner {
    margin: 0;
    padding: 0 8px;
    display: flex;
    align-items: center;
    height: 100%;
  }
  & > .v-select > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    & > input {
      display: none;
    }
    & > .v-select__selection {
      display: inline;
      padding: 0;
      margin: 0;
    }
  }
}
.close-chip.v-chip.v-size--default {
    background: map-get($tt-light-mono-8, 'base');
    border-radius: 5px;
    margin: 0;
}
</style>
